<template>
  <div>
    <Row>
      <i-col span="24" class="text-right">
        <i-button type="primary" class="m-r-5" size="small"  @click="showHistory=true">审批记录</i-button>
        <i-button type="primary" class="m-r-5" size="small"  v-if="isAuth('Statement_DownloadPDF')"  @click="handleDownloadPDF">下载审批记录</i-button>
        <i-button type="primary" class="m-r-5" size="small" @click="handleShowOrder">查看订单详情</i-button>
        <i-button type="primary" class="m-r-5" size="small"  @click="handleDownload">下载结算单</i-button>
        <i-button type="error" class="m-r-5" size="small"  @click="handleInvalid" v-if="allowInvalid">作废</i-button>
        <i-button type="error" class="m-r-5" size="small"  @click="handleChangeCode"  v-if="nullifiable">更改编号</i-button>
      </i-col>
    </Row>

    <statement-detail-component ref="statementDetail"  :versionId="otherParams"  :id="id" @on-inited="handleInited" />
    <invalid-component ref="invalidComponent" :id="id"  @on-success="handleInvalidSuccess"></invalid-component>
    <Modal v-model="showHistory" :width="800">
      <history :relateId="otherParams" :type="7" v-if="showHistory"></history>
    </Modal>

    <!-- 弹窗 订单详情 -->
    <OrderDetail ref="orderDetail"/>

  </div>
</template>

<script>
import History from '@/components/approval/History'
import statementDetailComponent from '@/components/statement/StatementDetailComponent'
import OrderDetail from '@/components/order/common/ModalOrderDetail'
import InvalidComponent from '@/components/contract/contractDetail/detail/InvalidComponent'
import { invalidStatement, changeStatementCode } from '@/api/scp/statement'

import { downloadFileRequest } from '@/utils/download'

export default {
  components: {
    History,
    statementDetailComponent,
    OrderDetail,
    InvalidComponent
  },
  props: {
    baseId: {
      required: true
    },
    otherParams: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      showInvalid: false,
      desc: '',
      id: 0,
      orderId: 0,
      showHistory: false,
      allowInvalid: false,
      nullifiable: false,
      statement: {}
    }
  },
  methods: {
    handleReasonChanged (reason) {
      this.desc = reason
    },
    handleViewChanged (show) {
      this.showInvalid = show
    },
    handleInvalid () {
      this.$refs.invalidComponent.initPage()
    },

    handleInvalidSuccess () {
      this.$refs.statementDetail.getDetail()
    },
    handleConfirmed () {
      invalidStatement({ statementId: this.id, invalidMark: this.desc }).then(
        res => {
          if (res && !res.errcode) {
            this.$Notice.success({ desc: '结算单作废成功', title: '操作成功' })
            this.showInvalid = false
            this.$refs.statementDetail.getDetail()
          }
        }
      )
    },
    handleChangeCode () {
      let newCode = this.statement.code
      this.$Modal.confirm({
        title: '更改结算单的编号',
        render: h => {
          return h('Input', {
            props: {
              value: newCode
            },
            on: {
              'on-change': event => {
                newCode = event.target.value
              }
            }

          })
        },
        onOk: () => {
          const that = this
          changeStatementCode({ statementId: this.id, statementCode: newCode }).then(res => {
            if (res && !res.errcode) {
              that.$Notice.success({ desc: '更改编号成功' })
              that.$refs.statementDetail.getDetail()
            }
          })
        }
      })
    },
    handleDownload () {
      let downloadFilename = '广告发布结算单('
      downloadFilename += this.statement.advertiserName
      downloadFilename += this.statement.code
      downloadFilename += ').doc'

      downloadFileRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-scp/v1/statement/downloadStatement', { statementId: this.id }, downloadFilename)
    },
    handleDownloadPDF () {
      // const filename = '广告发布合同(' + this.contract.code + ')' + '.pdf'
      let downloadFilename = '广告发布结算单('
      downloadFilename += this.statement.advertiserName
      downloadFilename += this.statement.code
      downloadFilename += ').pdf'
      downloadFileRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-scp/v1/contractexport/downloadpdf', { contractId: this.id }, downloadFilename)
    },
    handleShowOrder () {
      this.$refs.orderDetail.init(this.orderId)
    },
    handleInited (statement) {
      this.statement = statement
      this.orderId = statement.orderIdList[0]
      this.nullifiable = (statement.status !== -1 && this.isAuth('Statement_ChangeCode'))
      this.allowInvalid = (statement.status !== -1 && this.isAuth('Statement_Invalid'))
    }
  },

  created () {
    this.id = this.baseId
  }

}
</script>
